<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="dash_card_body pb-0">
                <div class="header_section">
                  <h4 class="menu_header"><span > Today's Orders</span> </h4>
                </div>
                <div class="search_section mb-0">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group has-search">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="orderSearch" @Keyup="searchOrders()" class="form-control"
                          placeholder="Search">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mr-2" @click="searchOrders('search')">
                          <span v-if="searchingType == 'search'"><span v-html="searchspinner"></span></span> Search
                        </button>
                      </div>
                    </div>
    
                   
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="row">
                        <div class="col-md-3 pad-r-0">
    
                          <Multiselect v-model="storeSearch" @change="searchOrders()" :close-on-select="true"
                            :options="strsoptions" :custom-label="name" :searchable="true"
                            :placeholder="'Stores'" label="name" track-by="name"></Multiselect>
                        </div>
                        <div class="col-md-3 pad-r-0">
                          <select class="form-control" v-model="searchChannel" @change="searchOrders()">
                            <option value="">Channel</option>
                            <option value="FOODIE">FOODIE</option>
                            <option v-for="channel in channels" :key="channel.id" :value="channel.channel_name">
                              {{channel.channel_name}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <!--Status menu start-->
                  <div class="St-menu">
                    <div class="status_btn_group"></div>
                    <button :class="[{'active': statusType == ''},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('')">
                      <span >All</span>
                    </button>
                    <button :class="[{'active': statusType == 'Pending'},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('Pending')">
                      <span >Pending</span>
                    </button>
                    <button :class="[{'active': statusType == 'Failed'},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('Failed')">
                      <span >Failed</span>
                    </button>
                    <button :class="[{'active': statusType == 'Completed'},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('Completed')">
                      <span >Completed</span>
                    </button>
                  </div>
                  <!--Status menu End-->
                </div>
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Order Ref</th>
                        <th scope="col">Store</th>
                        <th scope="col">Channel</th>
                        <th scope="col">User</th>
                        <th scope="col"> Total </th>
                        <th scope="col">Payment Status</th>
                        <th scope="col" v-if="user.role.id != UserRoles.waiters">Order Status</th>
                        <th scope="col" v-if="user.role.id == UserRoles.waiters">Pos Details</th>
                        <th scope="col" v-if="user.role.id != UserRoles.waiters">Delivery Status</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="(order,oindx) in  orders" :key="oindx" >
                        <td>
                          <a class="title_text" href="javascript:void(0)" data-toggle="modal"
                            :data-target="'#orderDetail_'+order.id"> {{order.channel_display_id}}</a> <br> <br>

                          <router-link :to="{ name: 'order_details', params: { order_id:order.id }}">
                            <span class="title_text mt-1">view more</span>
                          </router-link>
                        </td>
                        <td>
                          {{order.store}} <br>
                          <span class="mt-4  text-muted"><small>{{formatDate(order.created_at,4)}}</small></span>
                          <div v-if="order.fargo ==1">
                            <span class="mt-2 pending">
                              <strong>Future Order Date:</strong> 
                              {{formatDate(order.fargo_on,3)}}
                            </span>
                          </div>
                        </td>
                        <td>
                          {{order.channel}}
                        </td>

                        <td>
                          <span v-if="order.customer_name"><strong>Name: </strong>{{order.customer_name}}
                            <br></span>
                          <span v-if="order.customer_phone"><strong>Phone: </strong>{{order.customer_phone}}
                            <br></span>
                          <span v-if="order.room_no">
                            <strong>Room: </strong> {{ order.room_no }} <br>
                          </span>
                          <span v-if="order.table_no">
                            <strong>Table: </strong> {{ order.table_no }}
                          </span>
                        </td>
                        <td class="td-price">
                          {{order.currency}} {{order.total_price > 0 ? formatPrice(order.total_price)
                            :formatPrice(order.sub_total_price + order.shipping_cost)}}
                        </td>
                        <td>
                          <span :class="[{'active': order.payment_status.toLowerCase() === 'completed'},{'disabled':order.payment_status.toLowerCase() === 'failed'},{'pending': order.payment_status === ''}]">
                            <strong>{{order.payment_status ? order.payment_status : 'PENDING'}}</strong>
                          </span>
                          <span  v-if="order.payment_code">
                            <br><br>
                            <strong>Code:
                            </strong>{{order.payment_code}}</span>
                          
                          <span v-if="order.user_id > 0">
                            <br><br>
                            <strong>Created by: {{order.user}}</strong>
                          </span>
                          <br><br>
                          <span v-if="order.channel =='FOODIE' && order.payment_status == '' || order.channel =='FOODIE' && order.payment_status == 'PENDING'">
                            <button class="btn btn-sm btn-default custom_btn custon_orange"
                              @click="payNow(order.id)"><span> Pay Now</span></button>
                          </span>
                          

                        </td>
                        <td v-if="user.role.id != UserRoles.waiters">
                          <span v-if="order.status">
                            {{order.status}}
                          </span>
                        </td>

                        <td v-if="user.role.id == UserRoles.waiters">                          
                          <div v-if="order.pos_status">
                            <span class="active" v-if="!order.added_to_bill"><strong>Posted Successfull</strong> <br></span> 
                            <span class="active" v-if="order.added_to_bill"><strong>Posted To Another Bill</strong> <br></span> 
                            <span><b>POS ID:</b>{{order.pos_data}}</span><br>
                            <span><b>Status:</b>{{order.pos_data_status}}</span> <br>
                            <span v-if="!order.added_to_bill"><b>Balance:</b> {{formatPrice(order.pos_data_balance)}}</span><br>
                          </div>
                          <div v-else :class="{'disabled_content':order.added_to_bill}">
                            <button type="button" class="btn btn-success btn-sm mr-1 mt-1 t_btn" data-toggle="modal" :data-target="`#pos_repos_btn_${order.id}`"> <span v-if="postingLoading==order.id"><i class='fa fa-spinner fa-spin' ></i></span>Post New Bill</button>
                            <button type="button" @click="setOrderId(order)" class="btn custon_orange btn-sm mt-1 t_btn" data-toggle="modal" :data-target="`#add_order_${order.id}`">Post To Another Bill</button>
                          </div>
                          <!-- posting to pos verifying modal -->
                          <div class="modal fade" :id="`pos_repos_btn_${order.id}`" tabindex="-1" role="dialog" aria-labelledby="productM" aria-hidden="true">
                            <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
                              <div class="modal-content">

                                <div class="modal-body">

                                  <div class="search_content pt-0 pb-0 pr-3 pl-3 mb-4">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <h5 class="modal-title" id="exampleModalLongTitle">Verify Code</h5>
                                        <button type="button" id="productM_close" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                    </div>
                                   
                                  </div>
                                  <hr>
                                  <input aria-invalid="false" v-model="employeeCode"  aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Employee Code" type="search" class="form-control">
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary t_btn" :id="`dismiss_btn_${order.id}`" data-dismiss="modal">Dismiss</button>
                                  <button type="button" class="btn custon_orange t_btn" v-if="!verifiedCode" @click="verifyWaiterCode(order.id,'post')"   > <span v-if="verifyingCode" ><i class='fa fa-spinner fa-spin '></i></span> Verify Code</button>
                                  <button type="button" class="btn custon_orange t_btn" v-if="verifiedCode" @click="posRePost(order.id,true)"  > <span v-if="postingLoading==order.id"><i class='fa fa-spinner fa-spin '></i></span>Post Bill</button>

                                </div>
                               
                              </div>
                              
                            </div>
                          </div>
                        
                         

                          <!-- end -->
                          <!-- adding order to another bill modal -->
                          <div class="modal fade" :id="`add_order_${order.id}`" tabindex="-1" role="dialog" aria-labelledby="productM" aria-hidden="true">
                            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                              <div class="modal-content">

                                <div class="modal-body">

                                  <div class="search_content pt-0 pb-0 pr-3 pl-3 mb-4">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <h5 class="modal-title" id="exampleModalLongTitle">Select from the orders below in which order ( {{ selectedOrderRef }}) should be added to:</h5>
                                        <button type="button" id="productM_close" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-6">
                                        <input aria-invalid="false" v-model="searchData" @keyup="getOrdersTobeAdded()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." type="search" class="form-control">
                                      </div>

                                    </div>
                                  </div>
                                  <hr>
                                  

                                  <div class="scrollable_content pr-3 pl-3 pt-0 pb-0">
                                    <div :class="['item_list',{'disabled_content': orderLoading}]">
                                      <div class="row">
                                        <div class="col-md-12">

                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th>#</th>
                                                <th>Order Ref</th>
                                                <th>Check ID</th>
                                                <th>Details</th>
                                                <th>Balance</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr v-for="(order, mdgindex) in filterOrders(recentOrders,selectedOrderId)" :key="mdgindex" >
                                                <!-- <div  v-if="order.id != selectedOrderId"> -->
                                                <td>
                                                  <input :id="'order_checkbx_'+order.id" v-model="parentorderId" :value="order.id" type="radio">
                                                </td>
                                                <td>{{ order.channel_display_id }}</td>
                                                <td>
                                                  {{ order.pos_data }}
                                                </td>
                                                <td>
                                                  <span v-if="order.customer_name"><strong>Name: </strong>{{order.customer_name}}
                                                    <br></span>
                                                  <span v-if="order.customer_phone"><strong>Phone: </strong>{{order.customer_phone}}
                                                    <br></span>
                                                  <span v-if="order.room_no">
                                                    <strong>Room: </strong> {{ order.room_no }} <br>
                                                  </span>
                                                  <span v-if="order.table_no">
                                                    <strong>Table: </strong> {{ order.table_no }}
                                                  </span>
                                                </td>
                                                <td>
                                                  {{formatPrice(order.pos_data_balance)}}
                                                </td>
                                              <!-- </div> -->
                                              
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div v-if="totalPages > 1">
                                        <Pagination :total-pages="totalPages" :per-page="itemsize" :current-page="itemPage" @pagechanged="onItemPageChange" />
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                

                                <div class="modal-footer">
                                  <input aria-invalid="false" v-model="employeeCode"  aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Employee Code" type="search" class="form-control">

                                  <button type="button" class="btn btn-secondary t_btn" :id="`order_dismiss_btn_${order.id}`" data-dismiss="modal">Dismiss</button>
                                  <button type="button" class="btn custon_orange t_btn" v-if="!verifiedCode" @click="verifyWaiterCode(order.id,'postanother')"   > <span v-if="verifyingCode" ><i class='fa fa-spinner fa-spin '></i></span> Verify Code</button>
                                  <button type="button" class="btn custon_orange t_btn"  v-if="verifiedCode" @click="postingToAnotherOrder(order.id,false)" > <span v-if="postingLoading==order.id"><i class='fa fa-spinner fa-spin '></i></span> Post Bill</button>
                                </div>
                               
                              </div>
                              
                            </div>
                          </div>
                        
                        </td>
                        
                        <td v-if="user.role.id != UserRoles.waiters">
                          <span>
                            {{order.shipping_method ? order.shipping_method === 'flat' ? 'flatrate' :
                              order.shipping_method : 'N/A' }}
                          </span>
                        </td>
                        <!--  -->
                        <!-- Modal -->
                        <div class="modal order_modal right" :id="'orderDetail_'+order.id"
                          aria-labelledby="myModalLabel2">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <!-- {{order}} -->
                              <div class="modal-header order_modal_header">
                                <h3 class="modal-title">Order <b>{{order.channel_display_id}}</b> </h3>
                                <button type="button" class="close button_close" data-dismiss="modal"
                                  aria-label="Close"><span aria-hidden="true">&times;</span></button>
                              </div>

                              <div class="modal-body">
                                <div class="padd">
                                  <div class="sec customer-D bd-b">
                                    <h4 class="c-title">Customer Details</h4>
                                    <span v-if="order.customer_name"><strong>Name: </strong>{{order.customer_name}}
                                      <br></span>
                                    <span v-if="order.customer_phone"><strong>Phone: </strong>{{order.customer_phone}}
                                      <br></span>
                                    <span v-if="order.customer_phone_code"> <strong>Phone Code:</strong>
                                      {{order.customer_phone_code}} <br></span>

                                    <span v-if="order.map_delivery_address"><strong>Address:
                                    </strong>{{order.map_delivery_address}} <br></span>
                                    <span v-if="order.delivery_address"><strong>More details:
                                    </strong>{{order.delivery_address}} <br></span>
                                    
                                    <span v-if="order.table_no"><strong>Table: </strong> {{ order.table_no }}<br></span>
                                    <span v-if="order.room_no"><strong>Room: </strong>{{order.room_no}} <br></span>

                                  </div>
                                  
                                  <div class="sec bd-b">
                                    <h4 class="c-title">Order Details</h4>
                                    <table class="order-table" width="100%" cellspacing="0" cellpadding="0">
                                      <tbody>
                                        <tr v-for="(item,itemindx) in order.items" :key="item.id">
                                          <td width="70%" valign="top"><b>{{itemindx + 1}}. {{item.name}}</b>

                                            <div class="indent" v-if="item.sub_items.length > 0">
                                              
                                              <ul>
                                                <li v-for="sub_item in item.sub_items" :key="sub_item">
                                                  <b>{{sub_item.modifier_group}}:</b> <span>{{sub_item.name}} </span>
                                                </li>
                                               
                                              </ul>
                                            </div>
                                            <div>
                                              <span v-if="item.special_instructions !==''"><b>Note</b>:
                                                <span>{{item.special_instructions}}</span></span>
                                            </div>
                                           
                                          </td>
                                          <td class="text-right" width="10%" valign="top">
                                            <b>x {{item.quantity}}</b> 
                                            <div class="indent_children" v-if="item.sub_items.length > 0">
                                              <ul >
                                                <li  v-for="sub_item in item.sub_items" :key="sub_item">
                                                  <span>
                                                    x {{sub_item.quantity}}
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                          <td class="text-right" width="30%" valign="top">
                                            <b>{{formatPrice(item.price_total)}}</b>
                                            <div class="indent_children" v-if="item.sub_items.length > 0">
                                              <ul >
                                                <li  v-for="sub_item in item.sub_items" :key="sub_item">
                                                  <span>
                                                    <b>{{formatPrice(sub_item.price_total)}}</b>
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>

                                            
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td class="text-right" colspan="2" valign="top" align="right">Order Amount:
                                          </td>
                                          <td class="text-right" valign="top"> <b>{{order.currency}}
                                            {{formatPrice(order.sub_total_price)}}</b></td>
                                        </tr>
                                        <tr>
                                          <td class="text-right" colspan="2" valign="top" align="right">Delivery Cost:
                                          </td>
                                          <td class="text-right" valign="top"><span
                                                                                v-if="order.shipping_cost"><b>{{formatPrice(order.shipping_cost)}}</b></span>
                                            <span v-else><b>0.00</b></span></td>
                                        </tr>
                                        <tr>
                                          <td class="text-right" colspan="2" valign="top" align="right">Order Total: </td>
                                          <td class="text-right" valign="top"> <b>{{order.currency}} {{order.total_price > 0 ?
                                            formatPrice(order.total_price) : formatPrice(order.sub_total_price +
                                              order.shipping_cost)}}</b></td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>

                                  <div class="sec bd-b payment-D">

                                    <h4 class="c-title">Payment Details</h4>
                                    <div>
                                      <span  v-if="order.payment_method"><b>Method:</b>{{order.payment_method}} </span><br>
                                      <span 
                                        v-if="order.payment_status"><b>Status:</b>{{order.payment_status}}</span><br>
                                      <span  v-if="order.payment_id"><strong>ID:
                                      </strong>{{order.payment_id}}</span> <br>
                                      <span  v-if="order.payment_code"><strong>Code:
                                      </strong>{{order.payment_code}}</span> <br>
                                      <span  v-if="order.user_id != 0"><strong>Updated By:
                                      </strong>{{order.user}}</span>
                                    </div>
                                    
                                    <div v-if="order.vouchers &&  order.vouchers.length > 0">
                                    
                                      <hr>
                                      <h4 class="c-title">Voucher Redemption Details</h4>
                                      <div></div>
                                      <span v-for="voucher in order.vouchers" :key="voucher.voucher_code">
                                        <b>Code: </b> {{voucher.voucher_code}} &nbsp; <b> Amount: </b> {{voucher.amount}}
                                        <br>
                                      </span> <br>
                                    </div>
                                    <hr>
                                    <div class="px-1 mt-2 mb-2" v-if="order.channel">
                                    
                                      <span class="p-title">Order Channel: </span>
                                      <span  v-if="order.channel">{{order.channel}}</span>
                                    </div>
                                    <hr>
                                    <div class="pos-data px-1 mt-1">
                                      <h4 class="c-title">POS Details</h4>
                                      <div v-if="order.pos_status">
                                        <b>POS ID:</b>{{order.pos_data}}<br>
                                        <b>Status:</b>{{order.pos_data_status}}<br>
                                        <b>Balance:</b> {{formatPrice(order.pos_data_balance)}}<br>
                                      </div>
                                      <div v-if="!order.pos_status">
                                        <p>{{order.pos_data}}</p> <br>
                                        <hr>
                                        <div class="form-group">
                                          <span class="py-3" style="font-weight: 500;font-size: 14px;">POS Details</span>
                                          <div class="row">
                                            <div class="col-md-8">
                                              <input type="text" class="form-control" v-model="itemPosId">
                                            </div>
                                            <div class="col-md-4">
                                              <button class="btn btn-orange"
                                                @click="getItem(order)">Search</button>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-if="orderItem.length > 0">
                                          <div v-for="(orderi,i) in orderItem" :key="i">
                                            <span><b>Item Name</b>{{orderi.name}}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div v-if="order.pos_logs.length > 0"  class=" px-1 mt-2 mb-1">
    
                                      <div>
                                        <!-- {{ order.pos_logs }} -->
                                        <table>
                                          <thead>
                                            <th>Posted By</th>
                                            <th>Added Order</th>
                                          </thead>
                                          <tbody>
                                            <tr v-for="log in  order.pos_logs" :key="log.order_id">
                                              <td>
                                                {{ log.name }}
                                              </td>
                                              <td>
                                                <span v-if="log.added_order_ref">
                                                  {{ log.added_order_ref }}
                                                </span>
                                                <span v-else> N/A</span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="sec bd-b">

                                    <!-- <div class="mark_as_ready mb-2" v-if="!order.delivery_status">
                                      <h4>Mark as ready for Pickup</h4> <br>
                                      <button class="btn btn-success btn-sm t_btn" @click="markAsReady(order.id)"><span
                                        v-if="isReadyspinner == order.id"></span>Mark as Ready</button>
                                    </div> -->
                                    <div class="order_delay mt-1" style="display:none">
                                      <h4>Set Delay</h4> <br>
                                      <input type="text" class="form-control col-md-7 mr-2" v-model="prepTime"> <button
                                        class="btn btn-success" @click="createDelay(order.id)"><span
                                        v-if="spinner == order.id"></span>submit</button>
                                    </div>
                                    <div class="delivery_details mt-1" v-if="order.shipping_method">
                                    
                                      <h4 class="c-title">Delivery Details</h4>
                                      <span> <b>Method:</b>{{order.shipping_method}}<br></span>
                                    </div>
                                    
                                    

                                  </div>
                                  <div class="sec bd-b" v-if="order.notes !=''">
                                    <div class="mt-1">
                                      <h4 class="c-title">Additional info</h4>
                                      <p>{{ order.notes }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="9">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                  <option :value="100">100</option>
                                </select>
                              </li>

                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page"
                              @pagechanged="onPageChange" />

                          </div>

                        </td>
                      </tr>
                    </tfoot>
                  </table>

                </div>
                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>

              </div>
            </div>

          </div>
             
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Howl} from 'howler';
import pako from 'pako'
import moment from 'moment';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import Pagination from '@/pages/pagination/pagination.vue';
import Multiselect from '@vueform/multiselect';
import { useToast } from "vue-toastification";
import Pusher from 'pusher-js' 
import audion from '../../../../public/audios/orderbeep.mp3' // '../../../../public/audios/orderbeep.mp3' 

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
const toast = useToast();
export default {
    components: {
        Pagination,
        Multiselect
    },
    data() {
        return {
            statusType: '',
            orders: [],
            start: '',
            end: '',
            orderSearch: '',
            stores: [],
            orderItem: [],
            searchingType: '',
            loading: false,
            page: 1,
            orderDelay: '',
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 50,
            debounce: null,
            storeSearch: '',
            menuItems: [],
            orderStatus: '',
            paymentStatus: '',
            productSearch: '',
            strsoptions: [],
            productOptions: [{
              "value": '',
              "name": "All Products"
            }],
            itemPosId: '',
            enterpageno: '',
            spinner: '',
            exportType: '',
            exportData: [],
            channels: [],
            isReadyspinner: false,
            prepTime: 0,
            searchChannel: '',
            statusSpinner: false,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i>",
            recentOrders: [],
            orderPage:1,
            searchData:'',
            orderRecordsPerPage: 10,
            orderTotalPages: 0,
            selectedOrderId: '',
            parentorderId:0,
            orderLoading:false,
            posStatus:'',
            postingLoading:false,
            verifiedCode: false,
            verifyingCode: false,
            employeeCode: '',
            autoRefresh:false
        }
    },
    beforeMount: async function () {
        this.getOrders();
        this.getOrdersTobeAdded();
        this.getBranches();
        this.getItems();
        this.getChannels();
        this.subscribe();
    },
    computed: {
        minDate() {
            return this.start;
        }
    },
    methods: {
      decompressData(data){
        const orderBytes = atob(data)
        const charData    = orderBytes.split('').map((x)=>{return x.charCodeAt(0);});
        const resultdata  = pako.inflate(new Uint8Array(charData));
        return String.fromCharCode.apply(null, new Uint16Array(resultdata));
      },
      
      subscribe () {
        const pusherKey = this.PusherConfig.key
        const pusherCluster = this.PusherConfig.cluster
        const pusher = new Pusher(pusherKey, { cluster: pusherCluster })
        const notificationEnabled = this.appConfigs.enable_order_alerts
        if(notificationEnabled){
          pusher.subscribe('new-order')
          pusher.bind(`${this.merchantID}_order`, data => {
            const order = JSON.parse(this.decompressData(data.order))
            this.orders = this.orders.filter((x) => {x.id == order.id})
            const orderExist = this.orders.filter((x) => {
              return x.id == order.id
            })
            if(orderExist.length == 0 && order !=undefined){
                this.orders = [order, ...this.orders]
            }
            this.playHowlerAudio()
          })
        }
    },
        
    playHowlerAudio(){
      const trackAudio = audion//  "https://soundbible.com/mp3/sms-alert-2-daniel_simon.mp3"
      const sound = new Howl({
        src: [trackAudio],
        autoplay: true,
      });
      sound.play()

    },
        
      filterPaymentStatus(type){
          this.paymentStatus = type
          this.statusType = type
          this.getOrders();
        },
        setOrderId(order){
          this.selectedOrderId = order.id,
          this.selectedOrderRef = order.channel_display_id
        },
        addParentOrder(orderId){
          this.parentorderId = orderId
        },
        clearSearch() {
            this.searchingType = 'clear';
            this.productSearch = '';
            this.paymentStatus = '';
            this.storeSearch = '';
            this.start = '';
            this.end = '';
            this.orderStatus = '';
            this.exportType = '';
            this.getOrders();
            
        },
        customFormatter(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        exportOrder(type) {
            this.searchingType = type;
            this.exportType = type;
            this.getOrders();
        },
        async getChannels() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.channels = response.data.data
                }
            });
        },
        async postingToAnotherOrder(orderid){
          try {
            if(this.parentorderId != 0){
              this.postingLoading = orderid
              const url = `${this.baseUrl}/orders/${this.parentorderId}/post-to-order`;
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              const payload = {
                  'merchant_id': parseInt(this.merchantID),
                  'user_id': this.user.id,
                  'child_order_id': orderid.toString(),
                  'employee': parseInt(this.employeeCode)
              }
              const response = await this.axios.post(url, payload, config)
              if(response.status == 200){
                this.postingLoading = false
                this.parentorderId = 0
                this.getOrders();
                document.getElementById(`order_dismiss_btn_${orderid}`).click()
              }
            }else{
              toast.error("please Select order to add to")
              document.getElementById(`order_dismiss_btn_${orderid}`).click()
            }
          } catch (error) {
            this.parentorderId = 0
            document.getElementById(`order_dismiss_btn_${orderid}`).click()
            const message = error.response.data.message !=undefined ?  error.response.data.message : "unable to add bill to another bill"
            this.postingLoading = false
            toast.error(message)
            
          }
          
          // 
        },
        async verifyWaiterCode(orderid,type){
          try {
            this.verifyingCode =  true
            const url = `${this.dashBaseUrl}/verify-waiter/${this.user.id}`;
            const payload = {
              'employee_code': this.employeeCode
            }
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const response =  await this.axios.post(url,payload,config)
            if(response.status == 200){
              Swal.fire({
                title: 'Verified Successfully',
                text: '',
                icon: 'success',
                confirmButtonText: 'Ok'
              })
              if(type=='post'){
                if(document.getElementById(`dismiss_btn_${orderid}`)){
                  document.getElementById(`dismiss_btn_${orderid}`).click()
                }
                this.posRePost(orderid)
              }else{
                document.getElementById(`order_dismiss_btn_${orderid}`).click()
                this.postingToAnotherOrder(orderid)
              }
              this.verifiedCode = true
              this.verifyingCode =false
            }
          } catch (error) {
            if(document.getElementById(`dismiss_btn_${orderid}`)){
                    document.getElementById(`dismiss_btn_${orderid}`).click()
                  }
            this.verifyingCode =false
            Swal.fire({
              title: '',
              text: 'Unable to verify you code please contant admin',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            // toast.error("unable to verify you code please contant admin")
          }
        },
        async posRePost(orderid,type=true) {
            this.postingLoading = orderid;
            const url = `${this.baseUrl}/orders/${orderid}/repost`;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const payload = {
                'merchant_id': parseInt(this.merchantID),
                'post_status':type,
                'user_id': this.user.id,
                'employee': parseInt(this.employeeCode)
            }
            this.axios.post(url, payload, config).then((response) => {
                this.postingLoading = false;
                if (response.status === 200) {
                  if(document.getElementById(`dismiss_btn_${orderid}`)){
                    document.getElementById(`dismiss_btn_${orderid}`).click()
                  }
                  this.getOrders();
                }
            }).catch((error) => {
                this.postingLoading = false;
                this.getOrders();
                if(document.getElementById(`dismiss_btn_${orderid}`)){
                    document.getElementById(`dismiss_btn_${orderid}`).click()
                  }
                if (error.response && error.response.status === 401) {
                  this.$router.push({
                      name: 'login'
                  });
                }
            })
        },
        searchOrders(type = '') {
            if (type !== '') {
                this.searchingType = type;
            }
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getOrders();
            }, 1000)
        },
        async getOrdersTobeAdded(){
          // 
          this.orderLoading = true;
            this.statusType = this.paymentStatus
            const response = await this.getOrderData(this.orderPage,this.orderRecordsPerPage,this.searchData, '', '', '',false,1)
            if (response.status === 200) {
              this.orderLoading = false;
              this.recentOrders = response.data.data.data;
              this.orderTotalPages = Math.ceil(response.data.data.total / this.orderRecordsPerPage)
            }else{
              this.orderLoading = false;
              this.searchData = '';
            }

        },
        filterOrders(orders,orderID){
          // console.log(orders)
          const data = orders.filter((x) => {
             return x.id  != orderID;
          })
          return data
        },
        async getOrderData(page,recordsPerPage, orderSearch, storeSearch, searchChannel, paymentStatus,addedToBill, posStatus){
          try {
            let url = `${this.dashBaseUrl}/recent-orders/${this.merchantID}`;
            url = new URL(url);
            url.searchParams.set('page', page);
            url.searchParams.set('size', recordsPerPage);
            if(orderSearch !=""){
              url.searchParams.set('search', orderSearch);
            }
            if(storeSearch !=''){
              url.searchParams.set('branch', storeSearch);
            }
            if(searchChannel !=''){
              url.searchParams.set('channel', searchChannel);
            }
            if (paymentStatus !== '') {
                paymentStatus = paymentStatus !='Pending' ? paymentStatus : ''
                url.searchParams.set('payment_status', paymentStatus);
            }
            if(addedToBill !==''){
              url.searchParams.set('added_to_bill',addedToBill)
            }
            if(posStatus !=''){
              url.searchParams.set('pos_status', posStatus)
            }
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const response = await this.axios.get(url, config)
            return response
            
          } catch (error) {
            return {
              'status': 400,
              'error': error
            }
          }
          
          // 
        },
        async getOrders() {
            this.loading = this.autoRefresh ? false: true;
            this.statusType = this.paymentStatus
            const response = await this.getOrderData(this.page,this.recordsPerPage,this.orderSearch, this.storeSearch, this.searchChannel, this.paymentStatus,'','')
            if (response.status === 200) {
              this.loading = false;
              this.orders = response.data.data.data;
              this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
              this.totalRecords = response.data.data.total;
            }else{
              this.loading = false;
              this.searchingType = '';
            }

        },

        
        async payNow(id){
          this.statusSpinner = id;
          try{
            let url = `${this.dashBaseUrl}/payment-page/${this.merchantID}`;
            url = new URL(url);
            url.searchParams.set('order_id', id);
            const response = await this.axios.get(url,this.apiRequestConfig())
            if(response.status === 200){
              // window.location = response.data.data;

              window.open(response.data.data, '_blank');
              this.statusSpinner = false;
            }
          }catch(error){
            this.statusSpinner = false;
            if(error.response && error.response.status === 401){
              this.$router.push({name:'login'})
            }
          }

        },
        async getBranches() {
            const url = this.baseUrl + "/branch?merchant_id=" + this.merchantID;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {
                        const stores = response.data.data;
                        this.stores = stores;
                        this.strsoptions.push({
                                      "value": "",
                                      "name": "All Stores ("+ stores.length +")"
                        })
                        for (let i = 0; i < stores.length; i++) {
                            this.strsoptions.push({
                                "value": stores[i].id,
                                "name": stores[i].branch_name
                            })
                        }
                    }
                }
            }).catch((error) => {
              if (error.response && error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            })

        },
        getItem(item) {
            const OrderStatus = [];
            if (this.itemPosId !== '') {
                const orderItems = item.items;
                this.orderItem = [];
                for (let i = 0; i < orderItems.length; i++) {
                    const pposId = orderItems[i].pos_id;
                    if (pposId.trim() == this.itemPosId.trim()) {
                        OrderStatus.push(orderItems[i]);
                    } else {
                        for (let x = 0; x < orderItems[i].sub_items.length; x++) {
                            const sposId = orderItems[i].sub_items[x].pos_id;
                            if (sposId.trim() == this.itemPosId.trim()) {
                                OrderStatus.push({
                                    name: `${orderItems[i].sub_items[x].name} (${orderItems[i].name})`
                                });
                            }
                        }
                    }
                }
            }
            this.orderItem = OrderStatus;
        },

        async getItems() {
            let url = `${this.baseUrl}/menu_item`;
            url = new URL(url);
            url.searchParams.set('merchant_id', this.merchantID);
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.get(url, config).then((response) => {
                this.menuItems = response.data.data
                for(let x=0; x< this.menuItems.length; x++){
                  this.productOptions.push({
                    "value": this.menuItems[x].menu_item.id,
                    "name":this.menuItems[x].menu_item.item_name
                  })

                }
                // this.productOptions
            }).catch((error) => {
              if (error.response && error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            })
        },
        onPageChange(page) {
            this.page = page
            this.getOrders();
        },
        onChangeRecordsPerPage() {
            this.getOrders();
            // ds
        },
        onOrderPageChange(page){
          this.orderPage = page
          this.getOrdersTobeAdded()
        }
    },
    mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>
